<template>
  <div class="hello">
	  <el-row>
	    <el-col :span="24">
			<div class="grid-content bg-purple-white">
				<img
				@mouseover="changeInterval(true)"
				@mouseleave="changeInterval(false)"
				v-for="(item,index) in photos"
				:key="index"
				:src="item.image"
				v-show="index === currentIndex"
			  />
			  <!-- //左侧按钮 -->
			  <div @click="clickIcon('up')" class="iconDiv icon-left">
				<i class="el-icon-arrow-left"></i>
			  </div>
			  <!-- //右侧按钮 -->
			  <div @click="clickIcon('down')" class="iconDiv icon-right">
				<i class="el-icon-arrow-right"></i>
			  </div>
			</div>
		</el-col>
	  </el-row>
	  <el-row>
	    <el-col :span="24">
	  		<div class="grid-content bg-purple-white">
				<img src="../assets/service.jpg" style="height: 80px;width: 260px;margin-top: 20px;"/>
			</div>
	  	</el-col>
	  </el-row>
	  <p style="margin-top:20px;">从PC到移动互联网我们打造一个全方位的互联网营销体系</p>
	  <el-row>
	    <el-col :span="4">
	  			<div class="grid-content bg-purple-white"></div>
	  		</el-col>
	  		<el-col :span="16">
	  			<div class="grid-content bg-purple-white">
	  				<div class="content" v-for="service in serviceScope">
						<img :src="service.thumbnail" style="height: 200px;"/>
						<div style="text-align: center;">{{service.name}}</div>
						<div style="text-align: center;">{{service.description}}</div>
	  				</div>
	  			</div>
	  		</el-col>
	  		<el-col :span="4">
	  			<div class="grid-content bg-purple-white"></div>
	  		</el-col>
	  </el-row>
	  <el-row>
	    <el-col :span="24">
	  		<div class="grid-content bg-purple-blue" style="line-height: 60px;font-size: 22px;color:#fff;">
				站在用户的角度思考问题，与客户深入沟通，找到网站设计与推广的最佳解决方案
			</div>
	  	</el-col>
	  </el-row>
	  <div class="scheme">
		  <el-row>
		    <el-col :span="24">
		  		<div class="grid-content">
		  			<img src="../assets/solutions.png" style="height: 90px;width: 310px;margin-top: 20px;"/>
		  		</div>
				<p>我们团队为客户提供各种类型的最优互联网整体解决方案</p>
		  	</el-col>
		  </el-row>
		  <el-row>
		    <el-col :span="4">
		  			<div class="grid-content"></div>
		  		</el-col>
		  		<el-col :span="16">
		  			<div class="grid-content ">
		  				<div class="link-item"
		                :class="{'link-item-active': solution.id == activeLinkId,'link-item-hover':index==hoverIndex}"
						v-for="(solution,index) in solutions" 
						:key="index" @mouseover="hoverIndex = index" @mouseout="hoverIndex = -1">
							<img :src="solution.thumbnail" style="height: 100px;width: 100px;"/>
							<div style="text-align: center;">{{solution.name}}</div>
							<div style="text-align: center;">{{solution.description}}</div>
		  				</div>
		  			</div>
		  		</el-col>
		  		<el-col :span="4">
		  			<div class="grid-content "></div>
		  		</el-col>
		  </el-row>
		  <a style="text-align: center;text-decoration: none;" href=""><el-button round>查看更多</el-button></a>
		  <div class="space"></div>
	  </div>
	  <el-row>
	    <el-col :span="24">
	  		<div class="grid-content bg-purple-white">
	  			<img src="../assets/cases.jpg" style="height: 80px;width: 300px;margin-top: 20px;"/>
	  		</div>
	  	</el-col>
	  </el-row>
	  <p style="margin-bottom: 40px;">一个人能走多远，取决于与谁同行，我们团队是一个富有理想和激情的团队，是一个蓬勃向上并富有朝气的团队，<br />
	  也是一个技术专业化、管理人性化、创新性和学习型的优秀团队。</p>
	  <el-row style="margin-bottom: 40px;">
	    <el-col :span="5" v-for="successcase in cases" style="margin-left: 60px;">
	  		<div class="grid-content bg-purple-white">
				<img :src="successcase.thumbnail" style="height: 300px;margin-top: 20px;"/>
				<div style="text-align: center;">{{successcase.name}}</div>
			</div>
	  	</el-col>
	  </el-row>
	  <a style="text-align: center;text-decoration: none;" href=""><el-button round>查看更多</el-button></a>
	  <div class="space"></div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  data () {
    return {
		photos: [],
		currentIndex :0,//当前所在图片下标
		timer:null,//定时轮询
		serviceScope: [],
		solutions: [],
		cases: [],
		hoverIndex: -1, // 菜单hover索引
		activeLinkId: 0, // 当前激活的菜单id
    }
  },
  created() {
  	this.getPhotos();
	this.startInterval();
	this.getSerViceScope();
	this.getSolution();
	this.getCases();
  },
  methods: {
	  getSerViceScope: function() {
		  var _this = this;
		  _this.axios.get(_this.$host + "/article_categories?parent_id=34")
		  .then(function(res){
		  	_this.serviceScope = res.data.data;
		  }, function(err) {
		  	
		  });
	  },
	  getPhotos: function() {
		  var _this = this;
		  _this.axios.get(_this.$host + "/slide?slide_id=8")
		  .then(function(res){
		  	_this.photos = res.data.data;
		  }, function(err) {
		  	
		  });
	  },
	  getSolution: function() {
		  var _this = this;
		  _this.axios.get(_this.$host + "/article_categories?parent_id=39")
		  .then(function(res){
		  	_this.solutions = res.data.data;
		  }, function(err) {
		  	
		  });
	  },
	  getCases: function() {
		  var _this = this;
		  _this.axios.get(_this.$host + "/article_categories?parent_id=44")
		  .then(function(res){
		  	_this.cases = res.data.data;
		  }, function(err) {
		  	
		  });
	  },
	  //开启定时器
		startInterval(){
			// 事件里定时器应该先清除在设置，防止多次点击直接生成多个定时器
			clearInterval(this.timer);
			this.timer = setInterval(()=>{
				this.currentIndex++;
				if(this.currentIndex > this.photos.length-1){
					this.currentIndex = 0
				}
			},3000)
		},
		// 点击左右箭头
		clickIcon(val){
			if(val==='down'){
				this.currentIndex++;
				if(this.currentIndex===this.photos.length){
					this.currentIndex = 0;
				}
			}else{
				if(this.currentIndex === 0){
					this.currentIndex = this.photos.length;
				}
				this.currentIndex--;
			}
		},
		//鼠标移入移出控制
		changeInterval(val){
			if(val){
				clearInterval(this.timer)
			}else{
				this.startInterval()
			}
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.el-row {
  &:last-child {
    margin-bottom: 0;
  }
}
.bg-purple-white {
  background: #fff;
}
.bg-purple-blue {
	background: #78d8d2;
}
.grid-content {
  min-height: 60px;
  text-align: center;
}
img {
	width: 100%;
	height: 600px;
}
/* 箭头图标 */
.iconDiv {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  font-size: 60px;
  cursor: pointer;
}
.iconDiv:hover {
  background-color:#b8ccc3;
}
.icon-left {
  left: 10px;
}
.icon-right {
  right: 40px;
}
.active {
  background-color: black !important;
}
.content {
	width: 21%;
	margin-top: 2%;
	margin-left: 3%;
	margin-bottom: 3%;
	float: left;
}
.scheme{
	background: url(../assets/scheme.jpg);
}
.space{
	height: 40px;
}
.link-item {
	width: 21%;
	margin-top: 2%;
	margin-left: 3%;
	margin-bottom: 3%;
	float: left;
}
 .link-item-hover {
    background-color: #f5f7fa;
	transition: 3s ease,left 3s ease-in-out;
}
.link-item-active {
	// 链接菜单激活样式
	color: #fff;
	background-color: #b3cae4;
}
</style>
