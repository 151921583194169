<template>
  <div id="app">
    <div v-show="$route.meta.istrun">
      <el-row>
        <el-col :span="12">
          <div class="grid-content bg-purple-white" style="height: 60px">
            <img src="./assets/logo.png" />
          </div>
        </el-col>
        <el-col :span="12">
          <div class="grid-content bg-purple-white">
            <div style="text-align: center">
              <span><i class="el-icon-phone"></i>4009969297</span>&nbsp;
              <span><i class="el-icon-message"></i> huoxun@126.com</span>&nbsp;
            </div>
            <nav>
              <router-link to="/">首页</router-link>&emsp;
              <router-link to="/about">关于我们</router-link>&emsp;
              <router-link to="/contact">联系我们</router-link>&emsp;
            </nav>
          </div>
        </el-col>
      </el-row>
    </div>
    <router-view />
    <div v-show="$route.meta.istrun">
      <el-row class="bg-purple-blue">
        <el-col :span="4">
          <div class="grid-content bg-purple-blue"></div>
        </el-col>
        <el-col :span="4">
          <div class="grid-content bg-purple-blue" style="padding-right: 66px">
            <div class="tx-column">关于我们</div>
            <div class="tx-details">
              我们专注于网站定制，始终追求“用最快的速度定制出最好的网站”。懂您所需、做您所想！我们一直在思考如何为客户创造更大的价值，让客户更省心!
            </div>
            <a
              style="
                border: 1px solid #fff;
                color: #000;
                text-align: center;
                text-decoration: none;
                padding: 10px;
                color: #fff;
              "
              href=""
              >查看更多>></a
            >
            <div class="space"></div>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="grid-content bg-purple-blue">
            <div class="tx-column">最新案例</div>
            <div class="tx-details">
              <div v-for="successcase in cases">{{ successcase.name }}</div>
            </div>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="grid-content bg-purple-blue">
            <div class="tx-column">服务范围</div>
            <div class="tx-details">
              <div class="tx-service" v-for="service in serviceScope">
                {{ service.name }}
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="grid-content bg-purple-blue">
            <div class="tx-column">联系我们</div>
            <div class="tx-details">
              <div>
                <img
                  style="height: 16px; width: 20px"
                  src="./assets/qq.png"
                />&emsp;Q Q:2851651389
              </div>
              <div>
                <img
                  style="height: 17px; width: 18px"
                  src="./assets/email.png"
                />&emsp;邮箱: huoxun@126.com
              </div>
              <div>
                <img
                  style="height: 17px; width: 20px"
                  src="./assets/phone.png"
                />&emsp;电话: 4009969297
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="grid-content bg-purple-blue"></div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24" class="bg-purple-blue">
          <div class="grid-content bg-purple-blue" style="font-size: 12px">
            <div>
              <a
                style="text-decoration: none; color: #fff"
                href="https://beian.miit.gov.cn/"
                >皖ICP备2021006673号-10</a
              >
            </div>
            <div>
              <a
                style="text-decoration: none; color: #fff"
                href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=34030002020846"
              >
                <img
                  style="height: 20px; width: 20px"
                  src="./assets/ghs.png"
                />皖公网安备 34030002020846号
              </a>
            </div>
            <div>
              <a
                style="text-decoration: none; color: #fff"
                href="https://dxzhgl.miit.gov.cn/dxxzsp/xkz/xkzgl/resource/qiyesearch.jsp?num=%25E5%25AE%2589%25E5%25BE%25BD%25E7%2581%25AB%25E8%25AE%25AF%25E7%25BD%2591%25E7%25BB%259C%25E7%25A7%2591%25E6%258A%2580%25E6%259C%2589%25E9%2599%2590%25E5%2585%25AC%25E5%258F%25B8&type=xuke"
                target="_blank"
                >皖B2-20220364</a
              >
            </div>
            <div>
              <a
                href="https://jitepay.oss-cn-shanghai.aliyuncs.com/storage/app_version/2023/12/21/e7543ed7-6eb9-4046-adda-a6b277851e57.png"
                target="_blank"
                style="text-decoration: none; color: #fff"
                >营业执照：91340303MA8LA5J0XW</a
              >
            </div>
            <div style="color: #fff">
              Copyright © 2021 - 2022 安徽火讯网络科技有限公司 版权所有
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      cases: [],
      serviceScope: [],
    };
  },
  created() {
    this.getCases();
    this.getSerViceScope();
    if (this.isMobile()) {
      window.location.replace("http://m.huoxunnet.cn/");
    }
  },
  methods: {
    getCases: function () {
      var _this = this;
      _this.axios.get(_this.$host + "/article_categories?parent_id=44").then(
        function (res) {
          _this.cases = res.data.data;
        },
        function (err) {}
      );
    },
    getSerViceScope: function () {
      var _this = this;
      _this.axios.get(_this.$host + "/article_categories?parent_id=34").then(
        function (res) {
          _this.serviceScope = res.data.data;
        },
        function (err) {}
      );
    },
    isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  a {
    font-weight: bold;
    color: #2c3e50;
    text-decoration: none;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.el-row {
  &:last-child {
    margin-bottom: 0;
  }
}
.bg-purple-white {
  background: #fff;
}
.grid-content {
  min-height: 60px;
  text-align: center;
  line-height: 30px;
}
img {
  height: 60px;
}
.bg-purple-blue {
  background: #78d8d2;
}
.tx-column {
  text-align: left;
  font-size: 20px;
  color: #fff;
  padding: 20px 0px;
}
.tx-details {
  text-align: left;
  font-size: 14px;
  color: #fff;
  height: 120px;
}
.tx-service {
  border: 1px solid #fff;
  padding: 10px;
  margin-right: 10px;
  width: 30%;
  height: 20px;
  margin-bottom: 20px;
  margin-top: 10px;
  float: left;
  line-height: 20px;
}
.space {
  height: 4px;
}
</style>
