import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import axios from "axios";

Vue.config.productionTip = false; //阻止启动生产消息
Vue.use(ElementUI);
Vue.prototype.axios = axios;
Vue.prototype.$host = "http://api.huoxunnet.cn/v1";

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
